import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <h2 className="title">Sivua ei löydy</h2>
      <p>Ikävä kyllä saavuit sivulle, jota ei ole olemassa.</p>
    </section>
  </Layout>
);

export default NotFoundPage;
